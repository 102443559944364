import { onMounted, onUnmounted, ref } from 'vue';

/**
 * Composable used to refresh the page after X minutes of inactivity. Inactivity is determined
 * by using the User Activation API. This composable is used on the Compare Locations List, Compare
 * Locations Map, Location, and Map pages.
 * SEE:
 * - https://github.com/cloudninewx/Projects/issues/897
 * - https://developer.mozilla.org/en-US/docs/Web/API/UserActivation
 */
export const useRefreshPage = (minutes = 15) => {
  const delay = minutes * 60 * 1000;
  const timeoutId = ref(null);

  const clearRefreshPageTimeout = () => {
    window.clearTimeout(timeoutId.value);
    timeoutId.value = null;
  };

  const setRefreshPageTimeout = () => {
    timeoutId.value = window.setTimeout(() => {
      let shouldReload = false;

      if (navigator?.userActivation?.hasBeenActive === false) {
        console.log(`Reloading page after ${minutes} minutes user has never been active.`);
        shouldReload = true;
      }
      else if (navigator?.userActivation?.isActive === false) {
        console.log(`Reloading the page after ${minutes} minutes, user is not active`);
        shouldReload = true;
      }
      else {
        const { hasBeenActive, isActive } = navigator?.userActivation || {};
        const hasBeenActiveMessage = hasBeenActive ? 'has been active' : 'has not been active';
        const isActiveMessage = isActive ? 'active' : 'not active';
        console.log(`Not reloading the page after ${minutes} minutes, user ${hasBeenActiveMessage} and user is ${isActiveMessage}. Resetting timeout.`);
        setRefreshPageTimeout();
      }

      if (shouldReload) {
        window.location.reload();
      }
    }, delay);
  };

  onMounted(() => {
    if (!navigator?.userActivation) {
      console.warn('useRefreshPage(): Not enabled, User Activation API not supported.');
      return;
    }

    if (timeoutId.value) {
      clearRefreshPageTimeout();
    }

    setRefreshPageTimeout();

    // DEBUG
    // console.log(`Add refresh page timeout (${timeoutId.value}) on mounted`);
  });

  onUnmounted(() => {
    if (timeoutId.value) {
      // DEBUG
      // console.log(`Remove refresh page timeout (${timeoutId.value}) on unmounted`);

      clearTimeout();
    }
  });
};
